import { isFilled } from '@prismicio/helpers'
import { FilledLinkToWebField } from '@prismicio/types'
import { html } from 'lit'
import { choose } from 'lit/directives/choose.js'
import { map } from 'lit/directives/map.js'
import logoSVG from '../../assets/logoSimpleSVG'
import logoBlackSVG from '../../assets/logoBlackSVG'
import profileSVG from '../../assets/profileSVG'
import {
  TmlNavigationDocumentData,
  SiteNavigationDocumentData,
} from '@repo/prismic-data/prismicio-types'
import { TMLNavigation } from './tml-navigation'

const MIN_HEIGHT = 80
const HOVER_DELAY = 200
const HOVER_ANIMATION_DURATION = 300

export class DesktopNavigation {
  private host: TMLNavigation
  private hoverTimeout: ReturnType<typeof setTimeout> | null = null

  constructor(host: TMLNavigation) {
    this.host = host
  }

  disconnectedCallback() {
    window.onresize = () => {}
  }

  private _resetTimeout() {
    setTimeout(() => {
      this.hoverTimeout = null
    }, HOVER_ANIMATION_DURATION)
  }

  private handleMouseEnter(index: number) {
    if (this.hoverTimeout) {
      clearTimeout(this.hoverTimeout)
    }

    this.hoverTimeout = setTimeout(() => {
      this.host._openDesktopMenuIndex = index

      this._resetTimeout()
    }, HOVER_DELAY)
  }

  private handleMouseLeave() {
    if (this.hoverTimeout) {
      clearTimeout(this.hoverTimeout)
    }

    this.hoverTimeout = setTimeout(() => {
      this.host._openDesktopMenuIndex = undefined

      this._resetTimeout()
    }, HOVER_DELAY)
  }

  private handleClick(index: number) {
    if (this.host._openDesktopMenuIndex === index && !this.hoverTimeout) {
      this.host._openDesktopMenuIndex = undefined
      return
    }
    this.host._openDesktopMenuIndex = index
  }

  render(data: TmlNavigationDocumentData) {
    const calculatedHeight = Math.abs(
      this.host.shadowRoot?.getElementById(
        `top-navigation-${this.host._openDesktopMenuIndex}`,
      )?.offsetHeight ?? 0,
    )
    const height = MIN_HEIGHT + calculatedHeight

    const navigation = document.querySelector('tml-navigation')
    let computed
    let color
    if (navigation) {
      computed = getComputedStyle(navigation)
      color = computed.getPropertyValue('--tml-navigation-background-color')
    }

    return html`
      <style>
        :host {
          --tml-navigation-background-color: transparent;
        }
      </style>
      <div
        class="desktop-background"
        style="transform: translateY(${height}px); "
      >
        ${color === '' || color === 'transparent'
          ? html`<div class="background-filter"></div>`
          : null}
      </div>

      <div class="desktop-main">
        ${isFilled.link(data.home_link) &&
        html` <div class="tml-logo">
          <a
            class="tml-logo-link"
            href=${data.home_link.url}
            target=${(data.home_link as FilledLinkToWebField).target}
          >
            ${this.host.inverseLogo ? logoBlackSVG : logoSVG}
            <span class="visually-hidden">Tomorrowland home</span>
          </a>
        </div>`}
        <ul class="top-navigation">
          ${map(data.slices, (slice, index) =>
            choose(slice.slice_type, [
              [
                'top_navigation',
                () => {
                  return html`<li>
                    <button
                      @mouseover=${(event: Event) => {
                        event.stopPropagation()
                        this.handleMouseEnter(index)
                      }}
                      @mouseout=${(event: Event) => {
                        event.stopPropagation()
                        this.handleMouseLeave()
                      }}
                      @click=${(event: Event) => {
                        event.stopPropagation()
                        this.handleClick(index)
                      }}
                      class="${this.host._openDesktopMenuIndex === index
                        ? 'active_top_navigation'
                        : ''}"
                    >
                      ${slice.primary.group_name}
                      <span
                        class="caret"
                        style="${this.host._openDesktopMenuIndex === index
                          ? 'transform: rotate(180deg);'
                          : ''}"
                      ></span>
                    </button>
                    <div
                      id="top-navigation-${index}"
                      class="top-navigation-submenu"
                      data-visible="${this.host._openDesktopMenuIndex ===
                      index}"
                      @mouseover=${(event: Event) => {
                        event.stopPropagation()
                        this.handleMouseEnter(index)
                      }}
                      @mouseout=${(event: Event) => {
                        event.stopPropagation()
                        this.handleMouseLeave()
                      }}
                    >
                      ${map(slice.items, (item) => {
                        const itemData =
                          item as unknown as SiteNavigationDocumentData

                        return html`<ul class="site-navigation">
                          <li class="site-navigation-main">
                            <a
                              href=${(
                                itemData.main_site_link as FilledLinkToWebField
                              ).url ?? '#'}
                              target=${(
                                itemData.main_site_link as FilledLinkToWebField
                              ).target}
                              data-items="${itemData.slices.length}"
                            >
                              ${itemData.main_site_name}
                            </a>
                          </li>
                          ${map(itemData.slices, (itemSlice) => {
                            return html`<li>
                              <a
                                href=${(
                                  itemSlice.primary
                                    .navigation_item_link as FilledLinkToWebField
                                ).url ?? '#'}
                                target=${(
                                  itemSlice.primary
                                    .navigation_item_link as FilledLinkToWebField
                                ).target}
                              >
                                ${itemSlice.primary.navigation_item_text}
                              </a>
                            </li> `
                          })}
                        </ul>`
                      })}
                    </div>
                  </li>`
                },
              ],
            ]),
          )}
        </ul>
        <ul class="right">
          ${isFilled.link(data.account_link) &&
          html` <li class="navigation-buttons">
              <slot name="language-picker"></slot>
            </li>

            ${this.host.loginButton
              ? html`<li class="navigation-buttons">
                  <a
                    class="tml-account-link"
                    href=${data.account_link.url}
                    target=${(data.account_link as FilledLinkToWebField).target}
                  >
                    ${profileSVG}
                    <div class="tml-account-text">
                      <span class="tml-account-name">
                        ${this.host.name || 'My Account'}
                      </span>
                      ${this.host.name
                        ? html`<span class="tml-account-subtext"
                            >My Account</span
                          >`
                        : null}
                    </div>
                  </a>
                </li>`
              : null}`}
        </ul>
      </div>
    `
  }
}

export default DesktopNavigation

import { isFilled } from '@prismicio/helpers'
import { FilledLinkToWebField, KeyTextField, SliceZone } from '@prismicio/types'
import { html } from 'lit'
import { choose } from 'lit/directives/choose.js'
import { map } from 'lit/directives/map.js'
import {
  TmlNavigationDocumentData,
  SiteNavigationDocumentData,
  TopNavigationSlice,
  NavigationItemSlice,
} from '@repo/prismic-data/prismicio-types'
import { ref, createRef, Ref } from 'lit/directives/ref.js'
import { TMLNavigation } from './tml-navigation'
import logoSVG from '../../assets/logoSimpleSVG'
import profileSVG from '../../assets/profileSVG'
import homeSVG from '../../assets/homeSVG'
import logoBlackSVG from '../../assets/logoBlackSVG'

type NavigationDocumentData = SiteNavigationDocumentData &
  Record<'languages', { [key: number]: string }[] | undefined> &
  Record<string, SliceZone<NavigationItemSlice>> & { id: string }

export class MobileNavigation {
  private host: TMLNavigation

  navRef: Ref<HTMLUListElement> = createRef()

  rootItem: NavigationDocumentData | undefined = undefined
  checkLanguages: boolean = false
  language: string = 'en'

  calculatePosition: boolean = true

  constructor(host: TMLNavigation) {
    this.host = host

    let lastScrollPosition =
      window.scrollY || document.documentElement.scrollTop

    window.addEventListener(
      'scroll',
      () => {
        const scrollTopPosition =
          window.scrollY || document.documentElement.scrollTop

        direction_check: if (scrollTopPosition > lastScrollPosition) {
          if (this.navRef.value?.classList.contains('scrollaway')) {
            break direction_check
          } else {
            this.navRef.value?.classList.add('scrollaway')
          }
        } else if (scrollTopPosition < lastScrollPosition) {
          if (this.navRef.value?.classList.contains('scrollaway')) {
            this.navRef.value?.classList.remove('scrollaway')
          } else {
            break direction_check
          }
        }
        lastScrollPosition = scrollTopPosition <= 0 ? 0 : scrollTopPosition
      },
      true,
    )
  }

  changeLanguage(data: TmlNavigationDocumentData, language: string) {
    if (this.host.closestNavId === undefined) return
    for (const item of data.slices) {
      for (const slice of item.items) {
        const subItem = slice as unknown as NavigationDocumentData
        if (subItem.id === this.host.closestNavId) {
          return (subItem.main_site_link as FilledLinkToWebField).url
        }

        const slices =
          subItem[`slices${language === 'en' ? '' : language}`] ||
          subItem.slices

        for (const subSubItem of slices) {
          if (subSubItem.id === this.host.closestNavId) {
            return (
              subSubItem.primary.navigation_item_link as FilledLinkToWebField
            ).url
          }

          for (const subSubSubItem of subSubItem.items) {
            //@ts-expect-error need to figure out how to properly type all of this
            if (subSubSubItem.id === this.host.closestNavId) {
              return (
                subSubSubItem.sub_navigation_item_link as FilledLinkToWebField
              ).url
            }

            return
          }
        }
      }
    }
  }

  findCurrentLevel(data: TmlNavigationDocumentData) {
    const setMenuIndices = (
      i: number | undefined,
      j: number | undefined,
      url: string,
      id: string,
      k?: number,
    ) => {
      this.host._openMobileMenuI = i
      this.host._openMobileMenuII = j
      this.host.closestNavUrl = url
      this.host.closestNavId = id
      if (k !== undefined) this.host._openMobileMenuIII = k
    }

    const findUrl = (url: string): boolean => {
      url = url.slice(0, -1)

      return data.slices.some((item, i) =>
        item.items.some((slice, j) => {
          const subItem = slice as unknown as NavigationDocumentData

          if ((subItem.main_site_link as FilledLinkToWebField).url === url) {
            setMenuIndices(i, j, url, subItem.id)
            return true
          }

          return subItem[
            `slices${this.language === 'en' ? '' : this.language}`
          ]?.some((subSubItem, k) => {
            if (
              (subSubItem.primary.navigation_item_link as FilledLinkToWebField)
                .url === url
            ) {
              setMenuIndices(i, j, url, subSubItem.id)
              return true
            }

            return subSubItem.items?.some((subSubSubItem) => {
              if (
                (subSubSubItem.sub_navigation_item_link as FilledLinkToWebField)
                  .url === url
              ) {
                setMenuIndices(
                  i,
                  j,
                  url,
                  (subSubSubItem as unknown as { id: string }).id,
                  k,
                )
                return true
              }

              return false
            })
          })
        }),
      )
    }

    const recurse = (url: string) => {
      try {
        if (!findUrl(url)) {
          const fullURL = new URL(url)
          const pathParts = fullURL.pathname.split('/').filter(Boolean)

          if (pathParts.length > 0) {
            pathParts.pop()
            const newURL = `${fullURL.origin}/${pathParts.join('/')}/`
            recurse(newURL)
          } else {
            // Handle root path
            data.slices.some((item) =>
              item.items.some((slice) => {
                const subItem =
                  slice as unknown as SiteNavigationDocumentData & {
                    id: string
                  }
                const navLink = subItem.main_site_link as FilledLinkToWebField

                if (
                  navLink?.url &&
                  new URL(navLink.url).origin === fullURL.origin
                ) {
                  setMenuIndices(undefined, undefined, navLink.url, subItem.id)
                  return true
                }

                return false
              }),
            )
          }
        }
      } catch (error) {
        console.error('Error processing URL:', error)
      }
    }

    recurse(window.location.href)
  }

  renderSubSubSubNavigation(
    subSlice: NavigationItemSlice,
    subSliceIndex: number,
    backButton: string,
  ) {
    return html`
      <div
        class="sub-sub-navigation"
        data-visible="${this.host._openMobileMenuIII === subSliceIndex}"
      >
        <button
          class="back-button"
          @click=${(event: Event) => {
            event.stopPropagation()
            this.host._openMobileMenuIII = undefined
          }}
        >
          <span class="caret" style="transform: rotate(90deg);"></span>
          Back to ${backButton}
        </button>
        <h1>${subSlice.primary.navigation_item_text}</h1>
        <ul>
          ${map(
            subSlice.items,
            (subItem) =>
              html`<li>
                <a
                  class="link-button"
                  href=${(
                    subItem.sub_navigation_item_link as FilledLinkToWebField
                  ).url}
                  target=${(
                    subItem.sub_navigation_item_link as FilledLinkToWebField
                  ).target}
                >
                  ${subItem.sub_navigation_item_text}
                </a>
              </li>`,
          )}
        </ul>
      </div>
    `
  }

  renderSubSubNavigation(
    itemData: {
      main_site_name: KeyTextField
      slices: SliceZone<NavigationItemSlice>
    },
    itemIndex: number,
    backButton: string,
  ) {
    return html`<div
      class="sub-sub-navigation"
      data-visible="${this.host._openMobileMenuII === itemIndex}"
    >
      <button
        class="back-button"
        @click=${(event: Event) => {
          event.stopPropagation()
          this.host._openMobileMenuII = undefined
        }}
      >
        <span class="caret" style="transform: rotate(90deg);"></span>
        Back to ${backButton}
      </button>
      <h1>${itemData.main_site_name}</h1>
      <ul>
        ${map(itemData.slices, (subSlice, subSliceIndex) => {
          if (subSlice.items.length === 0) {
            return html`<li>
              <a
                class="link-button"
                href=${(
                  subSlice.primary.navigation_item_link as FilledLinkToWebField
                ).url}
                target=${(
                  subSlice.primary.navigation_item_link as FilledLinkToWebField
                ).target}
              >
                ${subSlice.primary.navigation_item_text}
              </a>
            </li>`
          }

          return html`<li>
            <button
              class="link-button"
              @click=${(event: Event) => {
                event.stopPropagation()
                this.host._openMobileMenuIII = subSliceIndex
              }}
            >
              ${subSlice.primary.navigation_item_text}
              <span class="caret"></span>
            </button>
            ${this.renderSubSubSubNavigation(
              subSlice,
              subSliceIndex,
              itemData.main_site_name ?? '',
            )}
          </li>`
        })}
      </ul>
    </div>`
  }

  renderSubNavigation(slice: TopNavigationSlice, slicesIndex: number) {
    return html`<div
      style="${this.host._openMobileMenuI === slicesIndex
        ? `height: ${slice.items.length * 54}px;`
        : ''}"
      class="sub-navigation"
    >
      <ul>
        ${map(slice.items, (item, itemIndex) => {
          const itemData = item as unknown as NavigationDocumentData
          let slices = itemData.slices
          if (this.language && itemData.languages && this.language != 'en') {
            slices = itemData[
              `slices${this.language}`
            ] as SliceZone<NavigationItemSlice>
          }
          if (slices && slices.length === 0) {
            return html`<li>
              <a
                class="link-button"
                href=${(itemData.main_site_link as FilledLinkToWebField).url}
                target=${(itemData.main_site_link as FilledLinkToWebField)
                  .target}
              >
                ${itemData.main_site_name}
              </a>
            </li>`
          }

          return html`<li class="sub-navigation-main">
            <button
              class="link-button"
              @click=${(event: Event) => {
                event.stopPropagation()
                this.host._openMobileMenuII = itemIndex
              }}
            >
              ${itemData.main_site_name}
              <span class="caret"></span>
            </button>
            ${this.renderSubSubNavigation(
              {
                main_site_name: itemData.main_site_name,
                slices: slices,
              },
              itemIndex,
              slice.primary.group_name ?? '',
            )}
          </li>`
        })}
      </ul>
    </div>`
  }

  renderTopNavigation(data: TmlNavigationDocumentData) {
    return html` <div
      class="navigation"
      data-visible="${this.host._openMobileMenu}"
    >
      <div class="background-filter"></div>
      <ul
        class="top-navigation"
        style="${this.host._openMobileMenuII !== undefined
          ? this.host._openMobileMenuIII !== undefined
            ? `transform: translateX(calc(-200vw + 16px));`
            : `transform: translateX(calc(-100vw + 16px));`
          : ''}"
      >
        ${map(data.slices, (slice, slicesIndex) =>
          choose(slice.slice_type, [
            [
              'top_navigation',
              () => {
                return html`<li>
                    <button
                      @click=${(event: Event) => {
                        event.stopPropagation()
                        this.host._openMobileMenuI =
                          this.host._openMobileMenuI !== slicesIndex
                            ? slicesIndex
                            : undefined
                      }}
                    >
                      ${slice.primary.group_name}
                      <span
                        class="caret"
                        style="${this.host._openMobileMenuI === slicesIndex
                          ? `transform: rotate(0deg);`
                          : ''}"
                      ></span>
                    </button>
                  </li>
                  ${this.renderSubNavigation(slice, slicesIndex)} `
              },
            ],
          ]),
        )}
      </ul>
      <slot name="language-picker"></slot>
    </div>`
  }

  isSlotFilled(slotName: string) {
    const slot = document.querySelector(
      `slot[name="${slotName}"]`,
    ) as HTMLSlotElement
    if (!slot) {
      return false
    }
    return slot.assignedNodes().length > 0
  }

  render(data: TmlNavigationDocumentData) {
    this.host.width = this.host.clientWidth

    window.onresize = () => (this.host.width = this.host.clientWidth)

    if (!this.checkLanguages) {
      data.slices.forEach((slice) => {
        const current = slice.items.find((item) => {
          const dataItem = item as unknown as NavigationDocumentData
          return (
            (dataItem.main_site_link as FilledLinkToWebField).url ===
            window.location.origin
          )
        }) as unknown as NavigationDocumentData

        if (current && current.languages && current.languages.length > 0) {
          this.rootItem = current as unknown as NavigationDocumentData
        }
      })

      if (this.rootItem) {
        this.language =
          new URL(document.location.href).pathname.split('/')[1] || 'en'

        if (
          !this.rootItem.languages?.some((lang) => {
            const langString = Object.keys(lang)
              .sort((a, b) => Number(a) - Number(b))
              .map((key) => lang[Number(key)])
              .join('')
            return this.language.toLowerCase() === langString.toLowerCase()
          })
        ) {
          this.language = 'en'
        }

        this.checkLanguages = !this.checkLanguages
      }
    }

    if (this.calculatePosition) {
      this.findCurrentLevel(data)
      this.calculatePosition = false
    }

    return html`
      <style>
        :host {
          --tml-navigation-background-color: hsl(0, 0%, 0%);
        }
      </style>
      <div class="mobile-main">
        <div class="bar">
          <ul class="mobile-main-left">
            ${isFilled.link(data.account_link) &&
            html`<li>
              <a
                class="tml-account-link profile-logo"
                href=${data.account_link.url}
                target=${(data.account_link as FilledLinkToWebField).target}
              >
                ${profileSVG}
                <span class="visually-hidden">My Tomorrowland account</span>
              </a>
            </li>`}
            ${isFilled.link(data.home_link) &&
            html`<li>
              <a
                class="tml-account-link home-logo"
                href=${data.home_link.url}
                target=${(data.home_link as FilledLinkToWebField).target}
              >
                ${homeSVG}
                <span class="visually-hidden">Tomorrowland home</span>
              </a>
            </li>`}
          </ul>
          ${isFilled.link(data.home_link) &&
          html`<a
            class="tml-logo"
            href=${data.home_link.url}
            target=${(data.home_link as FilledLinkToWebField).target}
          >
            ${this.host.inverseLogo ? logoBlackSVG : logoSVG}
            <span class="visually-hidden">Tomorrowland home</span>
          </a>`}
          <div class="mobile-main-right">
            <button
              @click=${() => {
                if (this.host._openMobileMenu) {
                  setTimeout(() => {
                    this.findCurrentLevel(data)
                  }, 500)
                } else {
                  this.findCurrentLevel(data)
                }

                this.host._openMobileMenu = !this.host._openMobileMenu
              }}
            >
              <span class="visually-hidden"
                >${this.host._openMobileMenu ? 'Close' : 'Open'}
                navigation</span
              >
              ${this.host._openMobileMenu
                ? html`<span class="cross"></span>`
                : html`<span class="hamburger"></span>`}
            </button>
          </div>
        </div>
        ${this.host.festivalButtons
          ? html`<ul class="main-nav" ${ref(this.navRef)}>
              ${map(data.mobile_main_navigation, (item) => {
                return (
                  isFilled.link(item.link) &&
                  html`<li>
                    <a
                      href=${item.link.url}
                      target=${(item.link as FilledLinkToWebField).target}
                    >
                      ${item.link_text}
                    </a>
                  </li>`
                )
              })}
            </ul>`
          : null}
        ${this.renderTopNavigation(data)}
        ${this.rootItem?.languages && !this.isSlotFilled('language-picker')
          ? html`<footer
              class="language-picker ${!this.host._openMobileMenu && 'hidden'}"
            >
              ${map(this.rootItem?.languages, (lang) => {
                const langString = Object.keys(lang)
                  .sort((a, b) => Number(a) - Number(b))
                  .map((key) => lang[Number(key)])
                  .join('')
                return html`<a
                  class="${langString.toLowerCase() ===
                    new URL(document.location.href).pathname.split('/')[1] &&
                  'underline'}"
                  href="${this.changeLanguage(data, langString) ?? '#'}"
                  @click=${() => (this.host.language = langString)}
                >
                  ${langString}
                </a>`
              })}
            </footer>`
          : null}
      </div>
    `
  }
}

export default MobileNavigation
